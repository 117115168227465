/* index.css */

/* Import Red Hat Display from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@500&display=swap');

/* Apply Red Hat Display to the body */
body {
  margin: 0;
  font-family: 'Red Hat Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Apply Red Hat Display to specific selectors */
/* For example, if you want to apply it to headings */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Red Hat Display', sans-serif;
}

/* Apply Red Hat Display to code elements */
code {
  font-family: 'Red Hat Display', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
